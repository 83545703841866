import React from 'react';
import BlueDot from '../components/BlueDot';
import Browser from '../assets/images/temp/Browser.png';
import Tracking from '../assets/images/Icons/Tracking.png';
import Expense from '../assets/images/Icons/Expense.png';
import CashFlow from '../assets/images/Icons/CashFlow.png';
import Insurance from '../assets/images/Icons/Insurance.png';
import Planning from '../assets/images/Icons/Planning.png';
import Budget from '../assets/images/Icons/Budget.png';
import WealthManagement from '../assets/images/WealthManagement.png';
import GridLeft from '../assets/images/GridLeft.png';
import GridRight from '../assets/images/GridRight.png';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const Solutions = () => {
    return (
        <>
            <Helmet>
                <title>Networth Tracker Solutions - Your Financial Growth, Our Commitment</title>
                <meta name="description" content="Elevate your finances with our expert, unbiased, data-driven solutions crafted exclusively for your benefit and not ours. We are committed to empowering you and enhancing your financial wellness, enabling you to reach your goals more efficiently." />
                <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="Expires" content="0" />
                <link rel="canonical" href="/solutions" />
            </Helmet>
            <div className='tracking-wide'>
                <NavBar />
                <div className=''>
                    <div className='relative px-5 lg:px-[120px] pt-8 pb-6 md:py-12 xl:py-20 bg-[#111827]'>
                        <div className='absolute md:left-0 top-0 md:top-4 opacity-15'>
                            <img src={GridLeft} alt='' />
                        </div>
                        <div className='absolute bottom-0 md:right-0 md:top-4 opacity-15'>
                            <img src={GridRight} alt='' />
                        </div>
                        <div className='flex flex-col items-center justify-center gap-4'>
                            <div className='text-[#0072DE] text-center font-semibold'>
                                Solutions
                            </div>
                            <div className='text-[#FFFFFF] text-center font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] w-full lg:w-[60%]'>
                                Your Financial Growth, Our Commitment
                            </div>
                            <div className='text-[#B0B0B0] text-center font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 w-full lg:w-[60%]'>
                                Elevate your finances with our expert, unbiased, data-driven solutions crafted exclusively for your benefit and not ours. We are committed to empowering you and enhancing your financial wellness, enabling you to reach your goals more efficiently.
                            </div>
                        </div>
                        <div className='py-8 flex items-center justify-center'>
                            <img src={Browser} alt='w-fit h-fit' />
                        </div>
                        <div className='grid grid-rows-1 md:grid-cols-2 xl:grid-cols-3 py-8 xl:pb-16 gap-8'>
                            <div className='flex'>
                                <div className='h-6 w-16'>
                                    <img src={Tracking} alt='' />
                                </div>
                                <div className='flex flex-col px-6'>
                                    <div className='text-[#FFFFFF] font-semibold text-[20px] leading-[30px]'>
                                        Networth Tracking
                                    </div>
                                    <div className='text-[#B0B0B0] font-normal text-[16px] leading-6'>
                                        Track all your Assets and Liabilities effortlessly to gain a clear picture of your financial health and progress towards your goals.
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='h-6 w-16'>
                                    <img src={Expense} alt='' />
                                </div>
                                <div className='flex flex-col px-6'>
                                    <div className='text-[#FFFFFF] font-semibold text-[20px] leading-[30px]'>
                                        Expense Optimization
                                    </div>
                                    <div className='text-[#B0B0B0] font-normal text-[16px] leading-6'>
                                        Our smart expense optimization tools identify cost savings without compromising your lifestyle or goals.
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='h-6 w-16'>
                                    <img src={CashFlow} alt='' />
                                </div>
                                <div className='flex flex-col px-6'>
                                    <div className='text-[#FFFFFF] font-semibold text-[20px] leading-[30px]'>
                                        Cash-flow management
                                    </div>
                                    <div className='text-[#B0B0B0] font-normal text-[16px] leading-6'>
                                        Our cash management services maximize the potential of your assets through optimal liquidity and strategic fund deployment.
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='h-6 w-16'>
                                    <img src={Insurance} alt='' />
                                </div>
                                <div className='flex flex-col px-6'>
                                    <div className='text-[#FFFFFF] font-semibold text-[20px] leading-[30px]'>
                                        Insurance
                                    </div>
                                    <div className='text-[#B0B0B0] font-normal text-[16px] leading-6'>
                                        Our insurance solutions shield you and your family from life's uncertainties, ensuring financial stability.
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='h-6 w-16'>
                                    <img src={Planning} alt='' />
                                </div>
                                <div className='flex flex-col px-6'>
                                    <div className='text-[#FFFFFF] font-semibold text-[20px] leading-[30px]'>
                                        Retirement Planning
                                    </div>
                                    <div className='text-[#B0B0B0] font-normal text-[16px] leading-6'>
                                        Our retirement planning services secure your financial future by tailoring solutions to meet your goals.
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='h-6 w-16'>
                                    <img src={Budget} alt='' />
                                </div>
                                <div className='flex flex-col px-6'>
                                    <div className='text-[#FFFFFF] font-semibold text-[20px] leading-[30px]'>
                                        Financial Budgeting
                                    </div>
                                    <div className='text-[#B0B0B0] font-normal text-[16px] leading-6'>
                                        Our budgeting tools provide expense tracking and financial optimization recommendations.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='px-5 xl:px-[120px] py-5'>
                        <div className='py-6 md:py-12'>
                            <div className='flex flex-col xl:flex-row gap-6 md:gap-14 xl:gap-20'>
                                <div className='w-full xl:w-[50%] space-y-4 flex flex-col justify-center'>
                                    <div className='flex items-center justify-center border rounded-[10px] px-2 py-1 w-fit gap-2'>
                                        <BlueDot />
                                        <div className='text-[#0A0A0A] font-medium text-[12px] leading-[18.6px]'>
                                            Manage
                                        </div>
                                    </div>
                                    <div className='text-[#0A0A0A] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8px]'>
                                        Wealth Management
                                    </div>
                                    <div className='text-[#737373] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
                                        We leverage advanced analytics and algorithmic models to construct customized investment portfolios that optimize your returns while managing risk in line with your financial objectives.
                                    </div>
                                </div>
                                <div className='w-full xl:w-[50%]'>
                                    <div className='flex items-center justify-center'>
                                        <div className='h-fit w-fit lg:h-[450px] lg:w-[560px] flex items-center justify-center'>
                                            <img src={WealthManagement} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Solutions;