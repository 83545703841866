import React from 'react';
import KhyatiAvatar from '../assets/images/Avatars/KhyatiAvatar.png';
import ImgOne from '../assets/images/BlogPageSix/ImgOne.png';

const BlogPageSixInfo = () => {
    return (
        <div className='px-5 xl:px-[120px]'>
            <div className='flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4'>
                <div className='text-[#0072DE] text-center font-semibold'>
                    Finance
                </div>
                <div className='text-[#111827] xl:px-5 font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.72] text-center'>
                    Cracking the Dilemma: Why Personal Finance Matters in India
                </div>
                <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center px-6 xl:px-10'>
                    Understanding personal finance is your ticket to achieving dreams and securing a stable future
                </div>
                <div className='flex gap-4'>
                    <div className='flex items-center justify-center'>
                        <img src={KhyatiAvatar} alt='' className='h-10 w-10 object-cover' />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                            Khyati Gandhi
                        </div>
                        <div className='text-[#4B5563] font-normal text-[14px] leading-5'>
                            Product Designer
                        </div>
                    </div>
                </div>
                <div className='xl:w-[1024px] xl:h-[550px]'>
                    <img src={ImgOne} alt='' className="w-full h-full object-cover rounded-[8px]" />
                </div>
            </div>
            <div className='flex flex-col gap-6 md:gap-10 lg:gap-12 text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 xl:px-52'>
                <div className='flex flex-col gap-5'>
                    <p>
                        Let's face it, in India, money matters. From that dream wedding to your child's education, financial security is a cornerstone of a good life. But between managing a joint family, rising living costs, and the pressure to "keep up with the Sharma's," personal finance can feel overwhelming.
                    </p>
                    <p>
                        Here's the truth: taking control of your finances isn't about deprivation or living like a monk. It's about <span className='text-[#111827] font-medium'>empowerment.</span> Understanding personal finance empowers you to:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Achieve Your Dreams: </span><span className='text-[#4B5563]'>Whether it's that dream vacation to Goa or owning your own home, personal finance helps you create a roadmap to get there.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Plan for Milestones: </span><span className='text-[#4B5563]'>Indian weddings are legendary, but they can also be a financial burden. Personal finance helps you plan for these milestones without derailing your long-term goals.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Secure Your Future: </span><span className='text-[#4B5563]'>Retirement might seem far away, but planning for it early ensures a comfortable golden age, free from financial worries.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Weather Life's Storms: </span><span className='text-[#4B5563]'>Unexpected medical bills or job loss can leave you scrambling. An emergency fund, built through smart financial planning, helps you weather these storms.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Break the Cycle of Debt: </span><span className='text-[#4B5563]'>Credit cards and easy loans can be tempting, but they can also trap you in a cycle of high-interest payments. Personal finance equips you to manage debt responsibly and achieve financial freedom.</span></li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Personal Finance for Every Desi:
                    </div>
                    <p>
                        The beauty of personal finance is its universality. Whether you're a young professional just starting out, a parent juggling multiple expenses, or nearing retirement, it's never too late (or too early) to take control. Here are some tips to get you started:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Track Your Expenses: </span><span className='text-[#4B5563]'>Knowledge is power. Track your income and expenses for a month to understand where your money goes. There are plenty of free budgeting apps to help!</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Set Realistic Goals: </span><span className='text-[#4B5563]'>Don't try to go from zero to hero overnight. Set achievable financial goals, like saving for a down payment on a house or building an emergency fund.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Embrace Traditional Wisdom: </span><span className='text-[#4B5563]'>Remember the "Kumbakarna" sleep some of us perfected as kids? Apply that same dedication to saving! Even small amounts saved regularly can grow significantly over time.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Invest Wisely: </span><span className='text-[#4B5563]'>Gold might be a cultural staple, but explore other investment options like mutual funds or Public Provident Fund (PPF) for long-term wealth creation.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Seek Guidance: </span><span className='text-[#4B5563]'>Don't be afraid to seek help! Talk to a financial advisor who understands the Indian financial landscape and can tailor a plan for your specific needs.</span></li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Networthtracker.in: Your Finance Buddy
                    </div>
                    <p>
                        We understand the unique challenges and aspirations of Indians when it comes to money. Here at Networthtracker.in, we want to be your one-stop shop for mastering your personal finance journey. Use our tools to track your income and expenses, set financial goals, and access valuable resources to make informed financial decisions.
                    </p>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Conclusion
                    </div>
                    <p>
                        Taking control of your finances isn't about sacrificing your chai breaks or that Diwali shopping spree. It's about taking charge of your future and achieving your financial dreams.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlogPageSixInfo;