import React from 'react';
import KhyatiAvatar from '../assets/images/Avatars/KhyatiAvatar.png';
import ImgOne from '../assets/images/BlogPageFive/ImgOne.png';

const BlogPageFiveInfo = () => {
    return (
        <div className='px-5 xl:px-[120px]'>
            <div className='flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4'>
                <div className='text-[#0072DE] text-center font-semibold'>
                    Budgeting
                </div>
                <div className='text-[#111827] xl:px-5 font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.72] text-center'>
                    Feeling Financially Frayed? Master the 50/30/20 Rule for Budgeting Bliss
                </div>
                <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center px-6 xl:px-10'>
                    Feeling overwhelmed by your finances? The 50/30/20 rule offers a straightforward approach to budgeting that prioritizes your needs
                </div>
                <div className='flex gap-4'>
                    <div className='flex items-center justify-center'>
                        <img src={KhyatiAvatar} alt='' className='h-10 w-10 object-cover' />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                            Khyati Gandhi
                        </div>
                        <div className='text-[#4B5563] font-normal text-[14px] leading-5'>
                            Product Designer
                        </div>
                    </div>
                </div>
                <div className='xl:w-[1024px] xl:h-[550px]'>
                    <img src={ImgOne} alt='' className="w-full h-full object-cover rounded-[8px]" />
                </div>
            </div>
            <div className='flex flex-col gap-6 md:gap-10 lg:gap-12 text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 xl:px-52'>
                <div className='flex flex-col gap-5'>
                    <p>
                        Ever feel like your paycheck disappears into a black hole before you can even blink? You're not alone. Budgeting can feel restrictive and complicated. But what if there was a simple, effective way to take control of your finances? Enter the <span className='text-[#111827] font-medium'>50/30/20 rule</span> - your one-stop shop for budgeting peace of mind.
                    </p>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        The 50/30/20 Rule: A Budgeting Breakdown
                    </div>
                    <p>
                        Imagine a pie. Now, slice it into three unequal pieces. Here's how the 50/30/20 rule allocates your hard-earned income:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>50% Needs: </span><span className='text-[#4B5563]'>This is the biggest slice, representing your essential expenses. Think rent/mortgage, utilities, groceries, transportation, minimum debt payments, and other non-negotiables.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>30% Wants: </span><span className='text-[#4B5563]'>This slice caters to your desires. It's for that weekend getaway, the new gadget you've been eyeing, or that fancy dinner out. Remember, "wants" shouldn't come at the expense of "needs."</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>20% Savings & Debt Repayment: </span><span className='text-[#4B5563]'>This slice is crucial for your financial future. Here's where you save for emergencies, retirement, or pay down high-interest debt.</span></li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Why Does the 50/30/20 Rule Work?
                    </div>
                    <p>
                        This rule's beauty lies in its simplicity and balance. Here's how it empowers you:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Prioritizes Needs: </span><span className='text-[#4B5563]'>It ensures your essentials are covered first, giving you peace of mind.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Allows for Fun: </span><span className='text-[#4B5563]'>The "wants" slice lets you enjoy life's little pleasures without guilt, as long as you stay within budget.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Promotes Saving: </span><span className='text-[#4B5563]'>The "savings & debt repayment" chunk is key to building a secure financial future.</span></li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Getting Started with the 50/30/20 Rule
                    </div>
                    <p>
                        Ready to try it? Here's a quick guide:
                    </p>
                    <div className='px-6'>
                        <ol className='list-decimal ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Track Your Income & Expenses: </span><span className='text-[#4B5563]'>Know where your money goes. Use budgeting apps, spreadsheets, or even pen and paper for a month.</span></li>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Calculate Your Take-Home Pay: </span><span className='text-[#4B5563]'>Subtract taxes and other deductions from your gross income.</span></li>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Allocate Percentages: </span><span className='text-[#4B5563]'>Use the 50/30/20 rule to divide your take-home pay into three categories.</span></li>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Set Realistic Goals: </span><span className='text-[#4B5563]'>Within each category, set realistic spending limits.</span></li>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Track & Adjust: </span><span className='text-[#4B5563]'>Regularly monitor your progress and adjust categories as needed.</span></li>
                        </ol>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Conclusion
                    </div>
                    <p>
                        <span className='text-[#111827] font-semibold leading-8'>Remember: </span><span className='text-[#4B5563]'>The 50/30/20 rule is a flexible framework. You can adjust the percentages based on your financial situation. For example, if you have high student loans, you might allocate more than 20% to debt repayment.</span>
                    </p>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        The 50/30/20 Rule: Your Path to Financial Freedom
                    </div>
                    <p>
                        The 50/30/20 rule is a powerful tool to transform your financial outlook. It promotes responsible spending, encourages saving, and empowers you to take control of your financial future. So, grab your metaphorical pie chart, and get ready to slice your way to budgeting bliss!
                    </p>
                    <p>
                        <span className='text-[#111827] font-semibold leading-8'>Bonus Tip: </span><span className='text-[#4B5563]'>Networthtracker.in can be your one-stop shop for managing your finances. Track your income and expenses, set financial goals, and make informed decisions to build a strong financial future!</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlogPageFiveInfo;